document.addEventListener("DOMContentLoaded", function () {
  function msieversion() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))
      alert('本網站採用 HTML5 文件格式，然而 IE 瀏覽器對其支援性不佳，建議您改用 Google Chrome 或 Firefox 更先進又安全的瀏覽器，以獲得更佳的上網體驗。');
    else
      return true;
    return false;
  }
  msieversion();

  //需要 jquery
  $(window).scroll(function () {
    if ($(this).scrollTop() > 200) {
      $('#gotoTop').fadeIn(600);
    } else {
      $('#gotoTop').fadeOut(600);
    }
  });
  $('#gotoTop').click(function (event) {
    event.preventDefault();

    $('html, body').animate({
      scrollTop: 0
    }, 600);
  });
  // const gotoTopBtn = document.getElementById("gotoTop");
  // if(gotoTopBtn !== 'undefined' && gotoTopBtn !== null) {
  //     document.addEventListener("scroll", function() {
  //        if(window.scrollY > 400) {
  //             gotoTopBtn.style.display = "block";
  //         } else {
  //             gotoTopBtn.style.display = "none";
  //         }
  //     })
  //     gotoTopBtn.addEventListener("click", function() {
  //         window.scrollTo({
  //             top: 0,
  //             behavior: "smooth"
  //         })
  //     });
  // }

  var popuplinks = document.querySelectorAll(".popup-del");
  popuplinks.forEach(function (el, index) {
    el.addEventListener("click", function (event) {
      event.preventDefault();
      if (confirm('確定嗎？這項操作無法回復。')) {
        const url = el.href;
        const remote_window = window.open($(this).attr("href"), "", "width=600,height=500,scrollbars=yes");

      }
      return false;
    });
  });

  var popupdellinks = document.querySelectorAll(".popup");
  popupdellinks.forEach(function (el, index) {
    el.addEventListener("click", function (event) {
      event.preventDefault();
      const url = el.href;
      const remote_window = window.open($(this).attr("href"), "", "width=800,height=600,scrollbars=yes");
      remote_window.focus();
      return false;
    });
  });

  const alerts = document.querySelectorAll(".alert.alert-dismiss");
  alerts.forEach(function (el) {
    const icon = document.createElement("i");
    icon.classList.add("fa", "fa-times");
    el.appendChild(icon);
    icon.addEventListener("click", function (ev) {
      el.style.display = "none";
    });
  });
});
